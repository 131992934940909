/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

// import { initThreeV3 } from './init-three-v3';

// import { setupTransitionAnimations, runTransitionAnimations } from './transitions';

// import * as Turbo from "@hotwired/turbo";
import { initSlider } from '../slider';
// import { initBookPageSlider } from '../slider';
// import PDFObject from "pdfobject";
// setupTransitionAnimations();

import lozad from 'lozad';
import Plyr from 'plyr';
import Dotdotdot from 'dotdotdot-js';

// import Swiper, { Autoplay, Lazy, EffectFade } from 'swiper';

// const scrollToAnchor = (aid) => {
//   var aTag = $("#"+ aid);
//   $('html,body').animate({scrollTop: aTag.offset().top},'slow');
// }
// let currentSlider = null;

document.addEventListener("DOMContentLoaded", function() {
  let players;

  if(document.querySelector(".video-player")){
    // const plyrPlayer = new Plyr('#player');
    players = Array.from(document.querySelectorAll('.video-player')).map((p) => new Plyr(p));
  }

  if(document.querySelector('.project-swiper')){
    initSlider(players);
  }




  const truncateCnt = document.querySelector( ".truncated-content" );
  const readMoreBtn = document.querySelector( ".read-more-btn" );

  if(truncateCnt && readMoreBtn) {
    const options = { height: 900 };
    const dot = new Dotdotdot( truncateCnt, options );
    const api = dot.API;

    readMoreBtn.addEventListener( 'click', ( ev ) => {
      ev.preventDefault();
      if(truncateCnt.classList.contains('ddd-truncated')){
        api.restore();
        ev.target.textContent = '[READ LESS]';
      } else {
        api.truncate();
        ev.target.textContent = '[READ MORE]';
      }
    });
  }

  // if(document.querySelector(".homepage .link-wrapper")) {
  //   const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  //   const mouse = { x: pos.x, y: pos.y };
  //   const speed = 0.01;
  //   const fpms = 60 / 1000;

  //   window.addEventListener("mousemove", e => {
  //     mouse.x = e.x;
  //     mouse.y = e.y;
  //   });

  //   const homeLinks = document.querySelectorAll('.link-wrapper h3');
  //   homeLinks.forEach((link) => {
  //     const linkImg = link.parentNode.querySelector('img');
  //     const xSet = gsap.quickSetter(linkImg, "x", "px");
  //     const ySet = gsap.quickSetter(linkImg, "y", "px");

  //     gsap.ticker.add((_time, deltaTime) => {

  //       var delta = deltaTime * fpms;
  //       var dt = 1.0 - Math.pow(1.0 - speed, delta);

  //       pos.x += (mouse.x - pos.x) * dt;
  //       pos.y += (mouse.y - pos.y) * dt;

  //       xSet(pos.x - (linkImg.width / 2));
  //       ySet(pos.y - (linkImg.height / 2));
  //     });
  //   });
  // }

  // Swiper.use([Autoplay, Lazy, EffectFade]);
  // var swiper = new Swiper('.project-swiper', {
  //   slidesPerView: 1,
  //   // spaceBetween: 30,
  //   effect: 'fade',
  //   fadeEffect: {
  //     crossFade: true
  //   },
  //   centeredSlides: true,
  //   // loop: true,
  //   // grabCursor: true,
  //   speed: 500,
  //   // autoplay: {
  //   //   delay: 3000,
  //   //   disableOnInteraction: false,
  //   // },
  //   lazy: {
  //     loadPrevNext: true,
  //     // loadPrevNextAmount: 3,
  //   },
  //   watchSlidesVisibility: true,
  // });

  // if(document.querySelector('.books.show .swiper-container')){
  //   currentSlider = initBookPageSlider();
  // }

  if(document.querySelector('.lozad')){
    const observer = lozad('.lozad', {
      rootMargin: '10px 0px',
      threshold: 0.1,
      enableAutoReload: true,
    });
    observer.observe();
  }

  // runTransitionAnimations();

  // document.querySelector(".back-to-top").addEventListener('click', function(){
  //   $('html,body').animate({ scrollTop: 0 }, 'slow');
  //   return false;
  // });

  document.querySelector(".burger-icon").addEventListener('click', function(){
    const burger = $(this);
    burger.toggleClass("menu-open");
    $('.mobile-menu-container').toggleClass("menu-open");
    $('body').toggleClass("menu-open");
  });

  // document.querySelector(".hamburger-slim").addEventListener('click', function(){
  //   const burger = $(this);
  //   burger.toggleClass("menu-open");
  //   $('.mobile-menu-container').toggleClass("menu-open");
  //   $('body').toggleClass("menu-open");
  // });


  // const sortOptions = document.querySelectorAll('.sort .dropdown-item');
  // if(sortOptions) {
  //   Array.from(sortOptions).forEach(function(element) {
  //     element.addEventListener('click', (ev) => {
  //       window.location.href = ev.target.href;
  //     });
  //   });
  // }

  if(document.querySelector('#drawings-page')){
    const container = document.querySelector("#drawings-page");
    const promptText = document.querySelector(".prompt");
    const touchPromptText = document.querySelector(".touch-prompt");
    const drawings = document.querySelectorAll(".drawing-img");
    let nextDrawingIndex = 0;
    let maxZIndex = -99999;

    let dWidth = 600.0;
    let dHeight = 500.0;

    if (window.innerWidth < 800) {
      dWidth = 260.0;
      dHeight = 220.0;
    }

    const generateRange = (start, stop, step) =>
      Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

    const widths = generateRange(100, window.innerWidth - 100, 20)
    const heights = generateRange(60, window.innerHeight - 60, 20)


    container.addEventListener('click', function(e){
      let mousePos = { x: e.clientX, y: e.clientY };
      let nextDrawing = drawings[nextDrawingIndex];

      let orientation = nextDrawing.dataset.orientation;
      let ratio = nextDrawing.dataset.ratio;

      if(orientation === 'landscape') {
        nextDrawing.style.width = `${dWidth}px`;
        nextDrawing.style.left = `calc(${mousePos.x}px - ${dWidth / 2}px)`;
        nextDrawing.style.top = `calc(${mousePos.y}px - ${dWidth / ratio / 2}px)`;
      } else {
        nextDrawing.style.height = `${dHeight}px`;
        nextDrawing.style.left = `calc(${mousePos.x}px - ${dHeight * ratio / 2}px)`;
        nextDrawing.style.top = `calc(${mousePos.y}px - ${dHeight / 2}px)`;
      }
      nextDrawing.style.display = 'block';
      nextDrawing.style.zIndex = maxZIndex;

      nextDrawingIndex = nextDrawingIndex < drawings.length - 1 ? nextDrawingIndex + 1 : 0;
      maxZIndex = maxZIndex + 1;

      // touchPromptText.style.display = 'none';
      touchPromptText.style.left = `${widths[Math.floor(Math.random() * widths.length)]}px`;
      touchPromptText.style.top = `${heights[Math.floor(Math.random() * heights.length)]}px`;
    });

    container.addEventListener('mousemove', function(e){
      let mousePos = { x: e.clientX, y: e.clientY }

      promptText.style.left = `calc(${mousePos.x}px - 3rem)`;
      promptText.style.top = `calc(${mousePos.y}px - 1.5rem)`;
    });
  }

  console.log('Page loaded!');
});
