// import Swiper JS
import Swiper, { Autoplay, Lazy, EffectFade } from 'swiper';
// import PDFObject from "pdfobject";

// import Swiper styles
// import style from 'swiper/swiper-bundle.min.css';
// console.log(style);

export const initSlider = (players) => {
  var slidesLength = document.querySelectorAll('.swiper-slide').length;

  Swiper.use([Autoplay, Lazy, EffectFade]);
  var mySwiper = new Swiper('.project-swiper', {
    slidesPerView: 1,
    // spaceBetween: 30,
    autoHeight: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    centeredSlides: true,
    loop: true,
    // grabCursor: true,
    speed: 500,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 3,
    },
    watchSlidesVisibility: true,
  });

  const prevSlideBtn = document.querySelector('.prev-slide-btn');
  const nextSlideBtn = document.querySelector('.next-slide-btn');
  const counterText = document.querySelector('.counter-text');
  const counterTextMobile = document.querySelector('.counter-text.mobile');

  const zeroPad = (num, places) => String(num).padStart(places, '0');

  mySwiper.on('slideChange', function () {
    if(counterText && counterTextMobile){
      counterText.innerHTML = zeroPad((mySwiper.realIndex + 1), 2);
      counterTextMobile.innerHTML = zeroPad((mySwiper.realIndex + 1), 2);
    }

    if(players) {
      players.forEach(player => {
        if(player.playing) {
          player.pause();
        }
      });
    }

    // if(mySwiper.realIndex === 0){
    //   prevBookBtn.classList.add('disabled');
    // } else if(mySwiper.realIndex + 1 === slidesLength) {
    //   nextBookBtn.classList.add('disabled');
    // } else {
    //   prevBookBtn.classList.remove('disabled');
    //   nextBookBtn.classList.remove('disabled');
    // }

    // document.querySelector('.book-info.visible').classList.replace('visible','hidden');
    // document.querySelector(`.book-info[data-index="${mySwiper.realIndex}"]`).classList.replace('hidden', 'visible');
  });

  nextSlideBtn.addEventListener('click', function(){
    mySwiper.slideNext();
  });

  prevSlideBtn.addEventListener('click', function(){
    mySwiper.slidePrev();
  });

  return mySwiper;
}

// export const initBookPageSlider = () => {
//   // var slidesLength = document.querySelectorAll('.swiper-slide').length;

//   Swiper.use([Autoplay, Lazy, EffectFade]);
//   var mySwiper = new Swiper('.swiper-container.book-page-swiper-cnt', {
//     // slidesPerView: 'auto',
//     spaceBetween: 30,
//     // effect: 'fade',
//     // fadeEffect: {
//     //   crossFade: true
//     // },
//     slidesPerView: 1,
//     centeredSlides: true,
//     loop: true,
//     grabCursor: true,
//     speed: 500,
//     // autoplay: {
//     //   delay: 3000,
//     //   disableOnInteraction: false,
//     // },
//     lazy: {
//       loadPrevNext: true,
//       // loadPrevNextAmount: 3,
//     },
//     breakpoints: {
//       768: {
//         slidesPerView: 1.6,
//       },
//     },
//     watchSlidesVisibility: true,
//   });

//   var infoSwiper = new Swiper('.swiper-container.book-page-info-cnt', {
//     // slidesPerView: 'auto',
//     effect: 'fade',
//     fadeEffect: {
//       crossFade: true
//     },
//     slidesPerView: 1,
//     // spaceBetween: 30,
//     centeredSlides: true,
//     loop: false,
//     // grabCursor: true,
//     speed: 500,
//     allowTouchMove: false,
//     autoHeight: true,
//     // autoplay: {
//     //   delay: 3000,
//     //   disableOnInteraction: false,
//     // },
//     lazy: {
//       loadPrevNext: true,
//       // loadPrevNextAmount: 3,
//     },
//     watchSlidesVisibility: true,
//   });

//   const prevBookBtn = document.querySelector('.prev-book');
//   const nextBookBtn = document.querySelector('.next-book');

//   nextBookBtn.addEventListener('click', function(){
//     mySwiper.slideNext();
//     // infoSwiper.slideNext();
//   });

//   prevBookBtn.addEventListener('click', function(){
//     mySwiper.slidePrev();
//     // infoSwiper.slidePrev();
//   });

//   mySwiper.on('slideChange', function () {
//     infoSwiper.slideTo(mySwiper.realIndex);
//   });

//   mySwiper.on('slideChangeTransitionEnd', function () {
//     const bookPreview = document.querySelector(".swiper-slide-active .book-preview");
//     if(bookPreview){
//       document.querySelector(".swiper-slide-active .preview-btn").addEventListener('click', function(){
//         bookPreview.classList.toggle('book-opened');
//         setTimeout(()=>{
//           infoSwiper.updateAutoHeight(500);
//         }, 500);
//         return false;
//       });
//     }

//     // const pdfViewer = document.querySelector('.swiper-slide-active .pdf-viewer');
//     // if (pdfViewer) {
//     //   const pdfUrl = document.querySelector('.swiper-slide-active .pdf-viewer').dataset.pdf;
//     //   PDFObject.embed(pdfUrl, ".swiper-slide-active .pdf-viewer", { pdfOpenParams: { view: 'FitH' } });
//     // }

//     // const title = document.querySelector('.book-page-swiper-cnt .swiper-slide-active .title').textContent;
//     // const url = document.querySelector('.book-page-swiper-cnt .swiper-slide-active').dataset.url;

//     // window.history.pushState({}, title, url);
//   });

//   return infoSwiper;
// }